import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Auth/auth.guard';
import { LogGuard } from './Auth/logGuard/log.guard';
import { LoginComponent } from './login/login.component';
import { PrintInnerComponent } from './print-inner/print-inner.component';
import { ScanDetailsComponent } from './scan-details/scan-details.component';
import { SmallQrPrintComponent } from './small-qr-print/small-qr-print.component';
import { TestComponent } from './test/test.component';
import { KisanComponent } from './kisan/kisan.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [LogGuard],
    component: LoginComponent,
  },
  {
    // path:'test',
    path:'scan-details',
    component:TestComponent
  },
  {
    path:'kisan',
    component:KisanComponent
  },
  {
    path: 'login',
    canActivate: [LogGuard],
    redirectTo: '',
    pathMatch: 'full', 
  },

  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'print-small-qr',
    component: SmallQrPrintComponent,
  },
  {
    path: 'scan-details2',
    component: ScanDetailsComponent,
  },
  {
    path: 'print-Inner',
    component: PrintInnerComponent,
  },

  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
